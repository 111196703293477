import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon'
import React from 'react'

function AllWheelDrive(svgProps: SvgIconProps) {
  return (
    <SvgIcon {...svgProps} viewBox="0 0 44 44">
      <path d="M13.8284 35.1716C14.5786 35.9217 15 36.9391 15 38V39C15 38.2044 15.3161 37.4413 15.8787 36.8787C16.4413 36.3161 17.2044 36 18 36C18.7956 36 19.5587 36.3161 20.1213 36.8787C20.6839 37.4413 21 38.2044 21 39C21 38.4696 21.2107 37.9609 21.5858 37.5858C21.9609 37.2107 22.4696 37 23 37C23.5304 37 24.0391 37.2107 24.4142 37.5858C24.7893 37.9609 25 38.4696 25 39V40H8C7.44772 40 7 39.5523 7 39V38C7 36.9391 7.42143 35.9217 8.17157 35.1716C8.92172 34.4214 9.93913 34 11 34C12.0609 34 13.0783 34.4214 13.8284 35.1716Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5 39C22.5 38.4477 22.9477 38 23.5 38H34.5C35.0523 38 35.5 38.4477 35.5 39C35.5 39.5523 35.0523 40 34.5 40H23.5C22.9477 40 22.5 39.5523 22.5 39Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.3413 10.4736C18.798 8.88096 20.4593 7.96006 22.052 8.41676L36.4709 12.5513C38.0636 13.008 38.9845 14.6693 38.5278 16.262L36.3227 23.9521L16.1362 18.1637L18.3413 10.4736ZM21.5007 10.3393C20.9698 10.187 20.4161 10.494 20.2638 11.0249L18.61 16.7925L34.9514 21.4783L36.6053 15.7107C36.7575 15.1798 36.4505 14.6261 35.9196 14.4738L21.5007 10.3393Z"
      />
      <path d="M13.3798 27.7764L19.1474 29.4302L18.3205 32.314C18.1682 32.8449 17.6145 33.1518 17.0836 32.9996L13.2385 31.8971C12.7076 31.7448 12.4007 31.191 12.5529 30.6602L13.3798 27.7764Z" />
      <path d="M27.7987 31.9111L33.5663 33.565L32.7394 36.4487C32.5871 36.9796 32.0334 37.2866 31.5025 37.1344L27.6574 36.0318C27.1265 35.8796 26.8196 35.3258 26.9718 34.7949L27.7987 31.9111Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.4118 17.2026L36.5983 22.991L37.5099 25.8492C37.6299 26.2253 37.6359 26.6286 37.527 27.0082L36.5914 30.2711C36.2869 31.3329 35.1794 31.9468 34.1176 31.6424L28.8307 30.1264L29.9332 26.2813C30.0854 25.7504 29.7785 25.1967 29.2476 25.0444L21.5575 22.8393C21.0266 22.6871 20.4728 22.9941 20.3206 23.525L19.218 27.37L13.9311 25.854C12.8693 25.5495 12.2554 24.442 12.5599 23.3802L13.4955 20.1172C13.6043 19.7377 13.8231 19.3988 14.1242 19.1434L16.4118 17.2026ZM26.9081 29.5751L27.7351 26.6913L25.8125 26.14L24.9856 29.0238L26.9081 29.5751ZM23.89 25.5888L23.0631 28.4726L21.1406 27.9213L21.9675 25.0375L23.89 25.5888ZM18.7409 23.5922C18.5125 24.3885 17.6819 24.849 16.8855 24.6206C16.0892 24.3923 15.6288 23.5616 15.8571 22.7653C16.0854 21.9689 16.9161 21.5085 17.7124 21.7368C18.5088 21.9652 18.9692 22.7958 18.7409 23.5922ZM32.2657 29.0308C33.0621 29.2591 33.8927 28.7987 34.1211 28.0023C34.3494 27.206 33.889 26.3753 33.0926 26.147C32.2963 25.9186 31.4656 26.3791 31.2373 27.1754C31.0089 27.9718 31.4694 28.8024 32.2657 29.0308Z"
      />
      <path d="M19.0269 11.7107L17.1044 11.1594C16.5735 11.0072 16.0197 11.3142 15.8675 11.8451L15.0406 14.7288C14.8883 15.2597 15.1953 15.8135 15.7262 15.9657L17.6487 16.517C18.1796 16.6692 18.7334 16.3623 18.8856 15.8314L19.7125 12.9476C19.8648 12.4167 19.5578 11.8629 19.0269 11.7107Z" />
      <path d="M39.2134 17.4988L37.2909 16.9475C36.76 16.7953 36.2062 17.1022 36.054 17.6331L35.227 20.5169C35.0748 21.0478 35.3818 21.6016 35.9127 21.7538L37.8352 22.3051C38.3661 22.4573 38.9199 22.1504 39.0721 21.6195L39.899 18.7357C40.0512 18.2048 39.7443 17.651 39.2134 17.4988Z" />
    </SvgIcon>
  )
}

export default AllWheelDrive
