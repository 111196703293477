import SvgIcon from '@mui/material/SvgIcon'
import React from 'react'

const AppleCarPlay = () => {
  return (
    <SvgIcon viewBox="12.59 12.621 145.078 145.078">
      <path
        d="M119.4 60.476c-7.672-10.563-20.121-17.431-34.175-17.431-23.312 0-42.21 18.898-42.21 42.21s18.898 42.21 42.21 42.21c14.028 0 26.458-6.843 34.132-17.373"
        fill="none"
      />
      <linearGradient
        id="a"
        gradientUnits="userSpaceOnUse"
        x1="-960.801"
        y1="1277.555"
        x2="-960.801"
        y2="1132.477"
        gradientTransform="matrix(1 0 0 -1 1045.93 1290.176)"
      >
        <stop offset="0" stopColor="#0bd319" />
        <stop offset=".22" stopColor="#18d722" />
        <stop offset="1" stopColor="#86fc6f" />
      </linearGradient>
      <path
        d="M157.668 125.402c0 17.838-14.459 32.297-32.296 32.297H44.886c-17.837 0-32.296-14.459-32.296-32.297V44.917c0-17.837 14.459-32.296 32.296-32.296h80.486c17.837 0 32.296 14.459 32.296 32.296v80.485z"
        fill="url(#a)"
      />
      <path
        d="M85.225 129.963c-24.652 0-44.707-20.055-44.707-44.707s20.055-44.707 44.707-44.707c14.269 0 27.798 6.901 36.195 18.46a2.498 2.498 0 0 1-4.041 2.935C109.92 51.674 97.9 45.542 85.225 45.542c-21.898 0-39.713 17.815-39.713 39.713 0 21.897 17.815 39.713 39.713 39.713 12.649 0 24.654-6.11 32.117-16.347a2.497 2.497 0 0 1 4.034 2.941c-8.397 11.523-21.912 18.401-36.151 18.401zM77.088 66.12c-.384-.283-.824-.533-1.185-.533a1.66 1.66 0 0 0-1.659 1.659v35.421a1.66 1.66 0 0 0 2.534 1.407c.311-.196 30.316-17.307 30.607-17.561.35-.304.571-.752.571-1.252 0-.43-.163-.821-.431-1.116-.304-.333-29.936-17.656-30.437-18.025z"
        fill="#fff"
      />
    </SvgIcon>
  )
}

export default AppleCarPlay
