import ShareIcon from '@mui/icons-material/Share'
import { useMediaQuery, useTheme } from '@mui/material'
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button'
import IconButton from '@mui/material/IconButton'
import Snackbar from '@mui/material/Snackbar'
import React, { useEffect, useState } from 'react'

interface iResultShareButton {
  link: string
  title: string
  isButton?: boolean
}

const ShareButton: React.FC<iResultShareButton> = ({ link, title, isButton }) => {
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [isNativeShare, setNativeShare] = useState(false)
  const isDesktop = useMediaQuery('(min-width:900px)')
  const theme = useTheme()

  useEffect(() => {
    if (typeof navigator.share === 'function') {
      setNativeShare(true)
    }
  }, [])

  const handleClose = () => {
    setSuccess(false)
    setError(false)
  }

  const handleShare = async () => {
    try {
      if (isNativeShare) {
        await navigator.share({ title, url: link })
        setSuccess(true)
      } else {
        await navigator.clipboard.writeText('https://www.comparacar.com.br' + link)
        setSuccess(true)
      }
    } catch (error) {
      setError(true)
    }
  }

  return (
    <>
      {!isButton ? (
        <IconButton onClick={handleShare}>
          <ShareIcon sx={{ fontSize: 18, color: theme.palette.text.primary }} />
        </IconButton>
      ) : (
        <Button startIcon={<ShareIcon />} onClick={handleShare} variant="text">
          Compartilhar
        </Button>
      )}
      {isDesktop && !isNativeShare && (
        <>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={error}
            autoHideDuration={5000}
            onClose={() => setError(false)}
          >
            <Alert onClose={handleClose} severity="error">
              Erro ao tentar compartilhar
            </Alert>
          </Snackbar>
          <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={success}
            autoHideDuration={5000}
            onClose={() => setSuccess(false)}
          >
            <Alert onClose={handleClose} severity="success">
              {isNativeShare ? 'Compartilhado com sucesso' : 'Link copiado para area de transferencia'}
            </Alert>
          </Snackbar>
        </>
      )}
    </>
  )
}

export default ShareButton
